<template>
    <b-row>
        <b-col class="py-2 py-sm-1" cols="7" sm="3" md="3" lg="3" xl="3">
            <b-btn :id="'btn_' + paramKey" class="device-button" style="padding: 0rem 0.25rem; margin: 0px 10px 0px 0px;">
                <i class="icon-info"></i>
            </b-btn>

            <label>{{ paramValue.name }}</label>
            <b-tooltip :target="'btn_' + paramKey" :title="paramValue.description" placement="right" triggers="click"></b-tooltip>
        </b-col>

        <b-col class="py-2 py-sm-1" cols="1" sm="2" md="2" lg="2" xl="1" v-if="isReadOnly(paramKey)">
            <label :id="labelIdFormat(paramKey)" style="text-align: right"><strong>{{ labelTextFormatter(paramKey, paramValue.value)
                }}</strong></label>
        </b-col>

        <b-col class="py-2 py-sm-1" cols="10" sm="7" md="7" lg="7" xl="5">
            <div v-if="paramValue.type === 'boolean'">
                <b-form-radio-group :id="inputIdFormat(paramKey)" :name="paramKey" v-model="value">
                    <b-form-radio value="1">On</b-form-radio>
                    <b-form-radio value="0">Off</b-form-radio>
                </b-form-radio-group>
            </div>

            <div v-if="paramValue.type === 'slider'">
                <vue-slider class="px-0" v-model="value" :dot-size="16" :height="6" :min="paramValue.min" :max="paramValue.max" :lazy="true"
                            ref="slider" tooltip="focus">
                </vue-slider>

                <b-carousel v-if="paramKey === 'p_1'" id="variant-carousel" class="slide" background="#ffffff" :interval="0" img-width="370"
                            img-height="186" v-model="variantSlide">

                    <div v-if="uiVersion === 'tdc_v0'">
                        <b-carousel-slide style="outline: none;" img-src="../img/variants/v0/variant1.jpg"></b-carousel-slide>
                        <b-carousel-slide style="outline: none;" img-src="../img/variants/v0/variant2.jpg"></b-carousel-slide>
                        <b-carousel-slide style="outline: none;" img-src="../img/variants/v0/variant3.jpg"></b-carousel-slide>
                        <b-carousel-slide style="outline: none;" img-src="../img/variants/v0/variant4.jpg"></b-carousel-slide>
                        <b-carousel-slide style="outline: none;" img-src="../img/variants/v0/variant5.jpg"></b-carousel-slide>
                        <b-carousel-slide style="outline: none;" img-src="../img/variants/v0/variant6.jpg"></b-carousel-slide>
                        <b-carousel-slide style="outline: none;" img-src="../img/variants/v0/variant7.jpg"></b-carousel-slide>
                        <b-carousel-slide style="outline: none;" img-src="../img/variants/v0/variant8.jpg"></b-carousel-slide>
                        <b-carousel-slide style="outline: none;" img-src="../img/variants/v0/variant9.jpg"></b-carousel-slide>
                        <b-carousel-slide style="outline: none;" img-src="../img/variants/v0/variant10.jpg"></b-carousel-slide>
                        <b-carousel-slide style="outline: none;" img-src="../img/variants/v0/variant11.jpg"></b-carousel-slide>
                        <b-carousel-slide style="outline: none;" img-src="../img/variants/v0/variant12.jpg"></b-carousel-slide>
                        <b-carousel-slide style="outline: none;" img-src="../img/variants/v0/variant13.jpg"></b-carousel-slide>
                    </div>

                    <div v-if="uiVersion === 'tdc_v1'">
                        <b-carousel-slide style="outline: none;" img-src="../img/variants/v1/variant1.jpg"></b-carousel-slide>
                        <b-carousel-slide style="outline: none;" img-src="../img/variants/v1/variant2.jpg"></b-carousel-slide>
                        <b-carousel-slide style="outline: none;" img-src="../img/variants/v1/variant3.jpg"></b-carousel-slide>
                        <b-carousel-slide style="outline: none;" img-src="../img/variants/v1/variant4.jpg"></b-carousel-slide>
                        <b-carousel-slide style="outline: none;" img-src="../img/variants/v1/variant5.jpg"></b-carousel-slide>
                        <b-carousel-slide style="outline: none;" img-src="../img/variants/v1/variant6.jpg"></b-carousel-slide>
                        <b-carousel-slide style="outline: none;" img-src="../img/variants/v1/variant7.jpg"></b-carousel-slide>
                    </div>

                </b-carousel>
            </div>

            <div v-if="paramValue.type === 'schedule'">
                <b-row class="">
                    <b-col cols="6">
                        <b-input type="time" min="00:00" max="23:59" step="300" v-model="startTime"></b-input>
                    </b-col>

                    <b-col cols="6">
                        <b-input type="time" min="00:00" max="23:59" step="300" v-model="endTime"></b-input>
                    </b-col>
                </b-row>
            </div>

            <div v-if="paramValue.type === 'offDailyWeekly'">
                <b-form-radio-group :id="inputIdFormat(paramKey)" :name="paramKey" v-model="value">
                    <b-form-radio value="0">Off</b-form-radio>
                    <b-form-radio value="1">Daily</b-form-radio>
                    <b-form-radio value="2">Weekly</b-form-radio>
                </b-form-radio-group>
            </div>

            <div v-if="paramValue.type === 'offOnSchedule'">
                <b-form-radio-group :id="inputIdFormat(paramKey)" :name="paramKey" v-model="value">
                    <b-form-radio value="0">Off</b-form-radio>
                    <b-form-radio value="1">On</b-form-radio>
                    <b-form-radio value="2">Schedule</b-form-radio>
                </b-form-radio-group>
            </div>

            <div v-if="paramValue.type === 'moduleType'">
                <b-form-radio-group :id="inputIdFormat(paramKey)" :name="paramKey" v-model="value">
                    <b-form-radio value="0">Disabled</b-form-radio>
                    <b-form-radio value="1">Thermostat</b-form-radio>
                    <b-form-radio value="2">Recycle</b-form-radio>
                    <b-form-radio value="3">On/Off</b-form-radio>
                </b-form-radio-group>
            </div>

            <div v-if="paramValue.type === 'storageSensor'">
                <b-form-radio-group :id="inputIdFormat(paramKey)" :name="paramKey" v-model="value">
                    <b-form-radio value="0">S1</b-form-radio>
                    <b-form-radio value="1">S2</b-form-radio>
                    <b-form-radio value="2">S3</b-form-radio>
                    <b-form-radio value="3">S4</b-form-radio>
                </b-form-radio-group>
            </div>

            <!--Readonly-->
            <div v-if="paramValue.type === 'moduleSensor'"></div>
            <!--Readonly-->
            <div v-if="paramValue.type === 'moduleRelay'"></div>

            <div v-if="paramValue.type === 'mode'">
                <b-form-radio-group :id="inputIdFormat(paramKey)" :name="paramKey" v-model="value">
                    <b-form-radio value="0">Heat</b-form-radio>
                    <b-form-radio value="1">Cool</b-form-radio>
                </b-form-radio-group>
            </div>

            <div v-if="paramValue.type === 'lowHigh'">
                <b-form-radio-group :id="inputIdFormat(paramKey)" :name="paramKey" v-model="value">
                    <b-form-radio value="1">Low</b-form-radio>
                    <b-form-radio value="2">High</b-form-radio>
                </b-form-radio-group>
            </div>

            <div v-else-if="!paramValue.type">
                <input type="number" autocomplete="off" class="form-control input-sm"
                       :id="inputIdFormat(paramKey)" :name="paramKey" v-model="value">
            </div>
        </b-col>

        <b-col class="py-2 py-sm-1" cols="1" sm="1" md="1" lg="1" xl="1" :id="pendingIdFormat(paramKey)" v-show="pending">
            <spinner size="22" class="float-left"></spinner>
        </b-col>

    </b-row>
</template>

<script>
    import {mapGetters} from 'vuex';
    import vueSlider from 'vue-slider-component';
    import 'vue-slider-component/theme/default.css';
    import Spinner from 'vue-simple-spinner'
    import {valueFormatter, isReadOnly} from "@/utilities/parameter.utility";

    export default {
        name: "Parameter",

        components: {
            Spinner,
            vueSlider
        },

        computed: {
            ...mapGetters({
                getParameters: 'virtualDevice/parameters',
                getUiVersion: 'virtualDevice/uiVersion'
            }),

            parameters: function () {
                return this.getParameters(this.$route.params.id);
            },

            uiVersion: function () {
                return this.getUiVersion(this.$route.params.id);
            },

            variantSlide: {
                get: function () {
                    return this.value - 1
                },

                set: function (newValue) {
                    //added to bypass vue warning
                }
            }
        },

        props: [
            'paramValue',
            'paramKey'
        ],

        data() {
            return {
                value: this.paramValue.value.toString(),
                pending: false,
                pendingInterval: null,
                physicalDeviceUpdate: false,
                retryInterval: null,
                intervalValues: [0, 0],
                startTime: "8:00",
                endTime: "12:00"
            }
        },

        watch: {
            value: function () {
                if (!this.physicalDeviceUpdate) {
                    let parameter = {};
                    parameter['id'] = this.$route.params.id;
                    parameter[this.paramKey] = this.value;

                    this.$axios.post('/update-parameter', {
                        data: parameter
                    });

                    this.pending = true;
                    if (this.pendingInterval !== null) {
                        clearInterval(this.pendingInterval);
                        clearInterval(this.retryInterval);
                    }

                    this.pendingInterval = setInterval(() => {
                        if (parseInt(this.parameters[this.paramKey].value) === parseInt(this.value)) {
                            this.pending = false;
                            clearInterval(this.pendingInterval);
                            clearInterval(this.retryInterval);
                        }
                    }, 100);

                    this.retryInterval = setInterval(() => {
                        this.$axios.post('/update-parameter', {
                            data: parameter
                        });
                    }, 5000, parameter)
                } else {
                    this.physicalDeviceUpdate = false;
                }
            },

            intervalValues: function () {
                let num1 = this.intervalValues[0];
                let num2 = this.intervalValues[1];

                num1 = this.minutesToHourMinutes(num1);
                num2 = this.minutesToHourMinutes(num2);

                this.value = this.timeIntervalToNumber(num1, num2);
            },

            startTime: function () {
                this.value = this.timeIntervalToNumber(this.startTime, this.endTime);
            },

            endTime: function () {
                this.value = this.timeIntervalToNumber(this.startTime, this.endTime);
            }
        },

        created() {
            this.$watch('parameters.' + this.paramKey + '.value', function () { //watch changes transmitted from physical device
                if (!this.pending && parseInt(this.value) !== parseInt(this.parameters[this.paramKey].value)) {
                    this.value = this.parameters[this.paramKey].value;
                    this.physicalDeviceUpdate = true;
                }
            });

            if (this.paramValue.type === 'slider' || this.paramValue.type === 'schedule') {
                this.$root.$on('refresh-slider', () => {
                    if (this.$refs.slider !== undefined && this.$refs.slider.refresh !== undefined) this.$refs.slider.refresh();
                });
            }

            if (this.paramValue.type === 'schedule') {
                this.intervalValues = this.numberToMinuteInterval(this.value);
                this.startTime = this.minutesToHourMinutes(this.intervalValues[0]);
                this.endTime = this.minutesToHourMinutes(this.intervalValues[1]);
            }
        },

        beforeDestroy() {
            clearInterval(this.pendingInterval);
            clearInterval(this.retryInterval);
        },

        methods: {
            isReadOnly(key) {
                return isReadOnly(key);
            },

            minutesToHourMinutes: function (minutes) {
                let h = Math.floor(minutes / 60);
                let m = minutes % 60;

                h = h < 10 ? '0' + h : h;
                m = m < 10 ? '0' + m : m;

                return h + ':' + m;
            },

            timeIntervalToNumber(num1, num2) {
                let interval = num1.replace(':', '') + num2.replace(':', '');

                let startHour = parseInt(interval.substr(0, 2));
                let startMinute = parseInt(interval.substr(2, 2));
                let endHour = parseInt(interval.substr(4, 2));
                let endMinute = parseInt(interval.substr(6, 2));

                let value = startHour >>> 0;
                value = (value << 8) | startMinute >>> 0;
                value = (value << 8) | endHour >>> 0;
                value = (value << 8) | endMinute >>> 0;

                return value;
            },

            numberToMinuteInterval(number) {
                let startHour = number >> 24;
                let startMinute = (number >>> 16) & 0xFF;
                let endHour = (number >>> 8) & 0xFF;
                let endMinute = (number) & 0xFF;

                return [startHour * 60 + startMinute, endHour * 60 + endMinute];
            },

            intervalLabelFormatter() {
                let intervalNumber = this.paramValue.value;
                let minuteInterval = this.numberToMinuteInterval(intervalNumber);

                if (minuteInterval[0] === minuteInterval[1]) {
                    return "Inactive";
                } else {
                    let startTime = this.minutesToHourMinutes(minuteInterval[0]);
                    let endTime = this.minutesToHourMinutes(minuteInterval[1]);

                    return startTime + " - " + endTime;
                }
            },

            pendingIdFormat(id) {
                return "pending_" + id;
            },

            labelIdFormat(id) {
                return "label_" + id;
            },

            inputIdFormat(id) {
                return "input_" + id;
            },

            labelTextFormatter(key, value) {
                return valueFormatter(key, value);
            }
        }
    }
</script>

<style scoped>

</style>















