<template>
    <b-tabs no-key-nav pills card lazy content-class="edit-section-tab">
        <b-tab title="General" active>
            <template slot="title"><i class="icon-settings px-2"></i></template>

            <GeneralSettings></GeneralSettings>
        </b-tab>

        <b-tab v-if="shouldShow('Settings')" title="Settings">
            <template slot="title"><i class="icon-wrench px-2"></i></template>
            <h4 style="padding-bottom: 1.2em;"><i><em>Settings</em></i></h4>

            <div v-if="shouldShow('Root Settings')">
                <Parameter v-for="(value, key) in parameters" :key="key" v-if='categoriesLookup["Settings"]["Root Settings"].indexOf(key) > -1'
                           :param-key="key" :param-value="value"></Parameter>
            </div>

            <div v-if="shouldShow('Thermostat')">
                <br><strong>Thermostat</strong>
                <hr>
                <br>
                <Parameter v-for="(value, key) in parameters" :key="key" v-if='categoriesLookup["Settings"]["Thermostat"].indexOf(key) > -1'
                           :param-key="key" :param-value="value"></Parameter>
            </div>

            <div v-if="shouldShow('Recycle')">
                <br><strong>Recycle</strong>
                <hr>
                <br>
                <Parameter v-for="(value, key) in parameters" :key="key" v-if='categoriesLookup["Settings"]["Recycle"].indexOf(key) > -1'
                           :param-key="key" :param-value="value"></Parameter>
            </div>

            <div v-if="shouldShow('Heat Transfer')">
                <br><strong>Heat Transfer</strong>
                <hr>
                <br>
                <Parameter v-for="(value, key) in parameters" :key="key" v-if='categoriesLookup["Settings"]["Heat Transfer"].indexOf(key) > -1'
                           :param-key="key" :param-value="value"></Parameter>
            </div>

            <div v-if="shouldShow('Heat Pump')">
                <br><strong>Heat Pump</strong>
                <hr>
                <br>
                <Parameter v-for="(value, key) in parameters" :key="key" v-if='categoriesLookup["Settings"]["Heat Pump"].indexOf(key) > -1'
                           :param-key="key" :param-value="value"></Parameter>
            </div>
        </b-tab>

        <b-tab v-if="shouldShow('Protections')" title="Protections">
            <template slot="title"><i class="icon-shield px-2"></i></template>
            <h4 style="padding-bottom: 1.2em;"><i><em>Protections</em></i></h4>

            <div v-if="shouldShow('System')">
                <strong>System</strong>
                <hr>
                <br>
                <Parameter v-for="(value, key) in parameters" :key="key" v-if='categoriesLookup["Protections"]["System"].indexOf(key) > -1'
                           :param-key="key" :param-value="value"></Parameter>
            </div>

            <div v-if="shouldShow('Collector')">
                <br><strong>Collector</strong>
                <hr>
                <br>
                <Parameter v-for="(value, key) in parameters" :key="key" v-if='categoriesLookup["Protections"]["Collector"].indexOf(key) > -1'
                           :param-key="key" :param-value="value"></Parameter>
            </div>

            <div v-if="shouldShow('Recooling')">
                <br><strong>Recooling</strong>
                <hr>
                <br>
                <Parameter v-for="(value, key) in parameters" :key="key" v-if='categoriesLookup["Protections"]["Recooling"].indexOf(key) > -1'
                           :param-key="key" :param-value="value"></Parameter>
            </div>

            <div v-if="shouldShow('Frost')">
                <br><strong>Frost</strong>
                <hr>
                <br>
                <Parameter v-for="(value, key) in parameters" :key="key" v-if='categoriesLookup["Protections"]["Frost"].indexOf(key) > -1'
                           :param-key="key" :param-value="value"></Parameter>
            </div>

            <div v-if="shouldShow('Seizing')">
                <br><strong>Seizing</strong>
                <hr>
                <br>
                <Parameter v-for="(value, key) in parameters" :key="key" v-if='categoriesLookup["Protections"]["Seizing"].indexOf(key) > -1'
                           :param-key="key" :param-value="value"></Parameter>
            </div>

            <div v-if="shouldShow('Storage Alarm')">
                <br><strong>Storage Alarm</strong>
                <hr>
                <br>
                <Parameter v-for="(value, key) in parameters" :key="key" v-if='categoriesLookup["Protections"]["Storage Alarm"].indexOf(key) > -1'
                           :param-key="key" :param-value="value"></Parameter>
            </div>
        </b-tab>

        <b-tab v-if="shouldShow('Manual Operations')" title="Manual Operations">
            <template slot="title"><i class="icon-game-controller px-2"></i></template>
            <h4 style="padding-bottom: 1.2em;"><i><em>Manual Operations</em></i></h4>

            <Parameter v-for="(value, key) in parameters" :key="key" :param-key="key" :param-value="value"
                       v-if='categoriesLookup["Manual Operations"].indexOf(key) > -1 && showManualOperationKey({key: key, deviceId: $route.params.id})'></Parameter>
        </b-tab>

        <b-tab v-if="shouldShow('Root Special Functions')" title="Special Functions">
            <template slot="title"><i class="icon-magic-wand px-2"></i></template>
            <h4 style="padding-bottom: 1.2em;"><i><em>Special Functions</em></i></h4>

            <div v-if="shouldShow('Root Special Functions')">
                <Parameter v-for="(value, key) in parameters" :key="key"
                           v-if='categoriesLookup["Special Functions"]["Root Special Functions"].indexOf(key) > -1' :param-key="key"
                           :param-value="value"></Parameter>
            </div>

            <div v-if="shouldShow('Datetime')">
                <br><strong>Datetime</strong>
                <hr>
                <br>
                <Parameter v-for="(value, key) in parameters" :key="key"
                           v-if='categoriesLookup["Special Functions"]["Datetime"].indexOf(key) > -1' :param-key="key"
                           :param-value="value"></Parameter>
            </div>
        </b-tab>
    </b-tabs>
</template>

<script>
    import Header from "@/components/layout/Header";
    import Parameter from "@/components/virtual-device/edit/parameter/Parameter";
    import GeneralSettings from "@/components/virtual-device/edit/general-settings/GeneralSettings";
    import {mapGetters} from 'vuex';

    export default {
        name: "View1",

        components: {
            Header,
            GeneralSettings,
            Parameter
        },

        data() {
            return {
                categoriesLookup: {
                    "Settings": {
                        "Root Settings": ["p_6", "p_7", "p_8", "p_9", "p_27", "p_28", "p_29", "p_30", "p_31", "p_32", "p_33", "p_34"],
                        "Thermostat": ["p_35", "p_36", "p_37", "p_38"],
                        "Recycle": ["p_39", "p_40", "p_63"],
                        "Heat Transfer": ["p_64", "p_65", "p_66", "p_67"],
                        "Heat Pump": ["p_41", "p_42", "p_43", "p_44", "p_45", "p_46", "p_47", "p_48", "p_49", "p_50", "p_51", "p_52", "p_53", "p_54",
                            "p_55", "p_56", "p_57", "p_58", "p_59", "p_60", "p_61", "p_68", "p_69"]
                    },
                    "Protections": {
                        "System": ["p_10", "p_11", "p_12"],
                        "Collector": ["p_13", "p_14", "p_15", "p_16"],
                        "Recooling": ["p_17", "p_18"],
                        "Frost": ["p_19", "p_20", "p_21"],
                        "Seizing": ["p_22", "p_23", "p_24"],
                        "Storage Alarm": ["p_25", "p_26"]
                    },
                    "Special Functions": {
                        "Root Special Functions": ["p_1"],
                        "Datetime": ["p_70", "p_71"]
                    },
                    "Manual Operations": ["p_72", "p_73", "p_74", "p_75"]
                }
            }
        },

        computed: {
            ...mapGetters({
                getParameters: 'virtualDevice/parameters',
                showManualOperationKey: 'virtualDevice/showManualOperationKey'
            }),

            parameters: function () {
                return this.getParameters(this.$route.params.id);
            }
        },

        methods: {
            shouldShow(categoryName) {
                let parameters = Object.keys(this.parameters);

                if (categoryName === "Root Settings" && this.categoriesLookup['Settings']['Root Settings'] !== undefined) {
                    return parameters.some(r => this.categoriesLookup['Settings']['Root Settings'].includes(r));
                } else if (categoryName === "Thermostat" && this.categoriesLookup['Settings']['Thermostat'] !== undefined) {
                    return parameters.some(r => this.categoriesLookup['Settings']['Thermostat'].includes(r));
                } else if (categoryName === "Recycle" && this.categoriesLookup['Settings']['Recycle'] !== undefined) {
                    return parameters.some(r => this.categoriesLookup['Settings']['Recycle'].includes(r));
                } else if (categoryName === "Heat Transfer" && this.categoriesLookup['Settings']['Heat Transfer'] !== undefined) {
                    return parameters.some(r => this.categoriesLookup['Settings']['Heat Transfer'].includes(r));
                } else if (categoryName === "Heat Pump" && this.categoriesLookup['Settings']['Heat Pump'] !== undefined) {
                    return parameters.some(r => this.categoriesLookup['Settings']['Heat Pump'].includes(r));
                } else if (categoryName === "System" && this.categoriesLookup['Protections']['System'] !== undefined) {
                    return parameters.some(r => this.categoriesLookup['Protections']['System'].includes(r));
                } else if (categoryName === "Collector" && this.categoriesLookup['Protections']['Collector'] !== undefined) {
                    return parameters.some(r => this.categoriesLookup['Protections']['Collector'].includes(r));
                } else if (categoryName === "Recooling" && this.categoriesLookup['Protections']['Recooling'] !== undefined) {
                    return parameters.some(r => this.categoriesLookup['Protections']['Recooling'].includes(r));
                } else if (categoryName === "Frost" && this.categoriesLookup['Protections']['Frost'] !== undefined) {
                    return parameters.some(r => this.categoriesLookup['Protections']['Frost'].includes(r));
                } else if (categoryName === "Seizing" && this.categoriesLookup['Protections']['Seizing'] !== undefined) {
                    return parameters.some(r => this.categoriesLookup['Protections']['Seizing'].includes(r));
                } else if (categoryName === "Storage Alarm" && this.categoriesLookup['Protections']['Storage Alarm'] !== undefined) {
                    return parameters.some(r => this.categoriesLookup['Protections']['Storage Alarm'].includes(r));
                } else if (categoryName === "Root Special Functions" && this.categoriesLookup['Special Functions']['Root Special Functions'] !== undefined) {
                    return parameters.some(r => this.categoriesLookup['Special Functions']['Root Special Functions'].includes(r));
                } else if (categoryName === "Datetime" && this.categoriesLookup['Special Functions']['Datetime'] !== undefined) {
                    return parameters.some(r => this.categoriesLookup['Special Functions']['Datetime'].includes(r));
                } else if (categoryName === "Manual Operations" && this.categoriesLookup['Manual Operations'] !== undefined) {
                    return parameters.some(r => this.categoriesLookup['Manual Operations'].includes(r));
                } else if (categoryName === "Protections" && this.categoriesLookup['Protections'] !== undefined) {
                    return parameters.some(r => this.categoriesLookup['Protections']['System'].includes(r));
                } else if (categoryName === "Settings" && this.categoriesLookup['Settings'] !== undefined) {
                    return parameters.some(r => this.categoriesLookup['Settings']['Root Settings'].includes(r));
                } else return false;
            }
        }
    }
</script>

<style scoped>

</style>
